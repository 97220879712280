import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { isNil } from 'lodash-es';

function ScrollToTop({ history, children }) {
  useEffect(() => {
        
    const unlisten = history.listen(() => {
      
        if (isNil(history)){
            if(isNil(history.location)){
                if(isNil(history.location.hash)){
                    if (history.location.hash.includes('#')){
                        return
                    }
                } 
            }
        }

        window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);